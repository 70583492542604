import React from "react"


interface ImenuSections {
  title: string,
  sizes?: Array<string>
  items: Array<IItems>
}

interface IItems {
  title: string,
  value?: string | Array<string>,
  description?: string,
  subItems?: Array<ISubItems> 
}

interface ISubItems {
  title: string,
  value:  string,
}

interface Props {
  listaMenu: ImenuSections
}

const Sizes = ({element}:any)=>{
  return(
    <div className='tamanhos'>
      {
        element.map((e:any, i:any)=>{
        return(
        <div key={i}>
          <span>{e}</span>
          <div className='linha-vertical'></div>
        </div>
        )})
      }
    </div>
  )
}

const MenuList: React.FC<Props> = ({listaMenu}) => {
  return (
    <div className="conteinerMenuList">
      <h2>{listaMenu.title}</h2>
      
      {
        listaMenu.sizes ? 
          <Sizes element={listaMenu.sizes}/>
        : <></>
      }

      <div className="menuList">
        { listaMenu.items.map((i,key) =>{
          return(
            <div key={key}>
            {
              i.subItems ? 
              <div className="menuListItemsS">
                <div className="nameValue">
                  <div className="itemName">
                    <h3>{i.title}</h3>
                  </div>

                  { i.subItems ? <></> : <div className="linha"></div> }

                  { i.subItems?

                    i.subItems.map((e, i)=>{
                      return(
                        <div key={i} className="subItems">
                          <h4>{e.title}</h4>
                          <div className="linha"></div>
                          <span>R${e.value}</span>
                        </div>
                      )
                    })

                  :
                    <span>R${i.value}</span>
                  }
                </div>

                <div className="descriptionItems">
                  <p>{i.description}</p>
                </div>
              </div> :
              
              <div className="menuListItems">
                <div className="nameValue">
                  <div className="itemName">
                    <h3>{i.title}</h3>
                  </div>

                  { i.subItems ? <></> : <div className="linha"></div> }

                  {
                    typeof i.value === 'string' ? <span>R${i.value}</span> : i.value && i.value.length >= 3 ? <></> :
                    <div className="multiplosValores">
                      {
                        i.value?.map((e, i) =>{
                          return(
                            <span key={i}>R${e}</span>
                          )
                        })
                      }
                    </div>
                  }
                </div>

                <div className="descriptionItems">
                  <p>{i.description}</p>
                </div>

                {
                  typeof i.value === 'object' && i.value.length >= 3 ? 
                  <div className="valores3">
                    {
                      i.value?.map((e, i) =>{
                        return(
                          <span key={i}>R${e}</span>
                        )
                      })
                    }
                  </div> : <></>
                }
              </div>
            }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MenuList