import { Swiper, SwiperSlide } from 'swiper/react';
import {QRCodeCanvas} from 'qrcode.react';
import whatsapp from './images/whats.png'
import localizacacao from './images/localizacao.png'
import reserva from './images/reserva.png'
import mao from './images/maozinha.png'
import cardapio from './images/cardapio.png'
import logo from './images/logo-vinho-marguerita.png'
import seta from './images/seta.png'
import MenuList from '../../components/menuList';
import { menuList, sectionsMenu } from './menu';
import { Pagination, HashNavigation } from 'swiper/modules';
// import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css';


const Marguerita = ()=>{
  const compartilhar = async () => {
    try {
      await navigator.share({
        title: 'Título do Compartilhamento',
        text: 'Texto do Compartilhamento',
        url: 'https://wa.me/557591413685',
      })
      console.log('Conteúdo compartilhado com sucesso!');
    } catch (error) {
      window.open('https://wa.me/557591413685', '_blank')
    }
  };

  const refSlides = [3, 3, 4, 4, 5, 5, 6, 7, 7, 8, 9, 10, 11]

  return (
    <div className='container'>
      <Swiper
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[Pagination, HashNavigation]}
        className="mySwiper"

        hashNavigation={{
          watchState: true,
        }}

        pagination={{
          dynamicBullets: true,
        }}
      >
        <div className='botao-compartihar'>
          <button title='botão compartilhar' onClick={compartilhar}>
            <span className="material-symbols-outlined">
              share
            </span>
          </button>
        </div>

        <SwiperSlide data-hash="slide1">
          <div className='page1'>
            <div className='logo'>
              <img src={logo} alt="" />
            </div>

            <div className='icones'>
              <a href="https://wa.me/557591413685" rel="noreferrer noopener" target='_blank'>
                <img src={whatsapp} alt="" />
                <span>Whatsapp</span>
              </a>

              <a href="https://wa.me/557591413685" rel="noreferrer noopener" target='_blank'>
                <img src={reserva} alt="" />
                <span>Reserva</span>
              </a>

              <a target='_blank' rel="noreferrer noopener" href="https://www.google.com/maps/place/Margherita+Pizzaria/@-11.6625045,-39.000628,20.29z/data=!4m12!1m5!3m4!2zMTHCsDM5JzQ0LjUiUyAzOcKwMDAnMDIuMSJX!8m2!3d-11.6623611!4d-39.0005833!3m5!1s0x7138d9506706ae5:0x1531371d4c77bec0!8m2!3d-11.6625406!4d-39.0005584!16s%2Fg%2F11sx3k66yt?hl=pt-BR&entry=ttu">
                <img src={localizacacao} alt="" />
                <span>Localização</span>
              </a>
            </div>

            <div className='toque'>
              <img src={mao} alt="" />
              <span>Clique nos ícones</span>
            </div>

            <div className='acoes'>
              <div className='acoes-salvar'>
                <button onClick={compartilhar}>Salvar</button>
              </div>
              
              <div className='ir-cardapio'>
                <a href="#slide2">
                  <img className='img-cardapio' src={cardapio} alt="" />
                  <span>Cardápio</span>
                  <img className='img-seta' src={seta} alt="" />
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide2">
          <div className='page2'>
            <ul>
              {sectionsMenu.map((e, i)=>{
                return (
                  <li key={i}>
                    <a href={`#slide${refSlides[i]}`}>{e}</a>
                  </li>
                )
              })}
            </ul>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide3">
          <div className='page3'>
            <MenuList listaMenu={menuList[0]}/>
            <MenuList listaMenu={menuList[1]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide4">
          <div className='page4'>
            <MenuList listaMenu={menuList[2]}/>
            <MenuList listaMenu={menuList[3]}/>
          </div>
        </SwiperSlide>
      
        <SwiperSlide data-hash="slide5">
          <div className='page4'>
            <MenuList listaMenu={menuList[4]}/>
            <MenuList listaMenu={menuList[5]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide6">
          <div className='page5'>
            <MenuList listaMenu={menuList[6]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide7">
          <div className='page6'>
            <MenuList listaMenu={menuList[7]}/>
            <MenuList listaMenu={menuList[8]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide8">
          <div className='page7'>
            <MenuList listaMenu={menuList[9]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide9">
          <div className='page8'>
            <MenuList listaMenu={menuList[10]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide10">
          <div className='page9'>
            <MenuList listaMenu={menuList[11]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide11">
          <div className='page10'>
            <MenuList listaMenu={menuList[12]}/>
          </div>
        </SwiperSlide>

        <SwiperSlide data-hash="slide12">
          <div className='box-QRCode'>
            <QRCodeCanvas className='QRCode' value="https://margherita.na.publypage.com/" />

            <div className='qrCode-botao-compartilhar'>
              <button>Compartilhar</button>
            </div>

            <div className='copyright'>
              <span>Feito pela PublyPage</span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Marguerita