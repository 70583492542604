export const sectionsMenu = ['ENTRADAS', 'VINHOS', 'ESPUMANTE', 'DRINKS', 'SUCOS','SOBREMESAS', 'BEBIDAS', 'QUICHE', 'ESFIRRA ABERTA', 'PIZZAS']

export const menuList = [
  {
    title: 'ENTRADAS',
    items: [
      {
        title: 'Focaccia',
        value: '16,00'
      },

      {
        title: 'Bruschetta',
        value: '27,50'
      },

      {
        title: 'Suco Natural',
        value: '54,00',
      },
    ],
  },

  {
    title: 'VINHOS',
    items: [
      {
        title: 'Casillero del diablo',
        subItems: [
          {
            title: '(Cabernet Suavignon)',
            value: '119,00'
          },

          {
            title: '(Suavignon/ Merlot/ Malbec)',
            value: '129,00'
          },
        ]
      },

      {
        title: 'Cava Negra',
        description: '(Cabernet Suavignon)',
        value: 'R$96,00'
      },

      {
        title: 'Beijamin',
        description: '(Malbec)',
        value: 'R$118,00',
      },

      {
        title: 'Sol do Chile',
        description: '(Cabernet Suavignon)',
        value: 'R$65,00'
      },

      {
        title: 'Trapiche Vineyard',
        description: '(Cabernet Suavignon)',
        value: 'R$129,00'
      },

      {
        title: 'Balbo',
        description: 'Malbe Rosé',
        value: 'R$85,00',
      },

      {
        title: 'Latitude 33',
        description: '(Cabernet Suavignon)',
        value: 'R$159,00'
      },

      {
        title: 'Reservado',
        description: '(Suavignon/ Merlot/ Cabernet suavignon, Chardonnay Chein Blanc)',
        value: 'R$89,00'
      },

      {
        title: 'Taça de vinho (Suave)',
        value: 'R$15,00',
      },

      {
        title: 'Taça de Rolha',
        value: 'R$10,00',
      },
    ],
  },

  {
    title: 'ESPUMANTE',
    items: [
      {
        title: 'Chandon Reserve Brut 750 ml',
        value: '240,00'
      },

      {
        title: 'Salton - Moscatel 750 m',
        description: 'Vinho Espulmante Branco',
        value: '92,00'
      },

      {
        title: 'Salton - Pro seco 750ml',
        description: 'Espulmante Natural Branco Brut',
        value: '92,00'
      },

      {
        title: 'Salton Brut (Espulmante natural brut)',
        description: 'Espulmante Natural Brut',
        value:  '92,00'
      },
    ]
  },

  {
    title: 'DRINKS',
    items: [
      {
        title: 'Aperol Spritz',
        value: '28,99',
      },

      {
        title: 'Lagoa Azul',
        value: '29,99',
      },
      
      {
        title: 'Espanhola',
        value: '29,90',
      }, 
      
      {
        title: 'Cozumel',
        value: '22,00',
      }, 
      
      {
        title: 'Caipiroska Limão',
        value: '20,00',
      },
      
      {
        title: 'Caipiroska Morango',
        value: '26,00',
      },
      
      {
        title: 'Gin Tônica',
        value: '27,90',
      }, 
      
      {
        title: 'Gin Tônica Morango',
        value: '29,90',
      },

      {
        title: 'Gin Tônica Maracujá',
        value: '27,90',
      },

      {
        title: 'Mojito',
        value: '27,00',
      },
    ]
  },

  {
    title: 'SUCOS',
    items: [
      {
        title: 'Abacaxi',
        value: '11,00',
      },

      {
        title: 'Abacaxi com Hortelã',
        value: '12,00',
      },

      {
        title: 'Limão',
        value: '11,00',
      },

      {
        title: 'Morango',
        value: '15,00',
      },

      {
        title: 'Maracujá',
        value: '12,00',
      },

      {
        title: 'Morango com Laranja',
        value: '16,90',
      },

      {
        title: 'Laranja',
        value: '16,00',
      },
    ],
  },

  {
    title: 'SOBREMESAS',
    items: [
      {
        title: 'Torta de Limão',
        value: '17,00',
      },

      {
        title: 'Torta de Maracujá',
        value: '17,00',
      },
    ]
  },

  {
    title: 'BEBIDAS',
    items: [
      {
        title: 'Água s/ gás',
        value: '4,00',
      },

      {
        title: 'Água c/ gás',
        value: '5,00',
      },

      {
        title: 'Antarctica Lata',
        value: '6,50',
      },

      {
        title: 'Antarctica Litro',
        value: '8,00',
      },

      {
        title: 'Coca-cola LS',
        value: '8,00',
      },

      {
        title: 'Coca-cola Lata Zero',
        value: '7,00',
      },

      {
        title: 'Coca-cola Lata',
        value: '7,00',
      },

      {
        title: 'Coca-cola Litro',
        value: '9,90',
      },

      {
        title: 'Água Tônica',
        value: '7,00',
      },

      {
        title: 'Schweppes',
        value: '7,50',
      },

      {
        title: 'Cerveja Heineken 330ml',
        value: '14,00',
      },

      {
        title: 'Cerveja Eisenbahn 330ml',
        value: '12,00',
      },

      {
        title: 'Cerveja Stella Artois 330m',
        value: '12,00',
      },

      {
        title: 'Cerveja Sparten 330ml',
        value: '12,00',
      },

      {
        title: 'Cerveja Budweiser 330ml',
        value: '12,00',
      },

      {
        title: 'Cerveja Estrela Galicia 330ml',
        value: '17,00',
      },

      {
        title: 'Cerveja Corona 330ml',
        value: '14,00',
      },
    ]
  },

  {
    title: 'QUICHE',
    items: [
      {
        title: 'Camarão',
        description: 'Muçarela, camarão, creme de leite e queijo ralado',
        value: '16,90',
      },

      {
        title: 'Carne de Sol',
        description: 'Muçarela, carne do sol, carne de charque, creme de leite e queijo ralado',
        value: '12,90',
      },

      {
        title: 'Frango',
        description: 'Muçarela, frango, milho verde, creme de leite e queijo ralado ',
        value: '10,90',
      },

      {
        title: 'Muçarela',
        description: 'Muçarela, creme de leite e queijo ralado',
        value: '9,90',
      },
    ]
  },

  {
    title: 'ESFIRRA ABERTA',
    items: [
      {
        title: 'Atum',
        description: 'Muçarela, atum, cebola, cebolinha, cenoura, coentro e creme de leite',
        value: '10,00',
      },

      {
        title: 'Camarão',
        description: 'Muçarela, camarão, cebola, cebolinha, coentro e creme de leite',
        value: '11,90',
      },

      {
        title: 'Calabresa',
        description: 'Muçarela, calabresa, cebola, cebolinha, coentro e creme de leite',
        value: '8,00',
      },

      {
        title: 'Carne Seca',
        description: 'Muçarela, carne seca, cebola, cebolinha, coentro e creme de leite',
        value: '9,00',
      },

      {
        title: 'Frango',
        description: 'Muçarela, frango, cebola, cebolinha, coentro e creme de leite',
        value: '8,00',
      },

      {
        title: 'Muçarela',
        description: 'Muçarela, cebola, cebolinha, coentro e creme de leite',
        value: '7,00',
      },
    ]
  },
  
  {
    title: 'PIZZA TRADICIONAL',
    sizes: ['Pequena', 'Grande'],
    items: [
      {
        title: 'Bacon',
        description: 'Muçarela, bacon, cebola e orégano.',
        value: ['30,00', '40,00'],
      },

      {
        title: 'Calabresa',
        description: 'Muçarela, calabresa, cebola e orégano',
        value: ['32,00', '44,00'],
      },

      {
        title: 'Frango',
        description: 'Muçarela, frango, cebola e orégano',
        value: ['31,00', '42,00'],
      },

      {
        title: 'Lombinho',
        description: 'Muçarela, lombinho, cebola, azeitona e orégano',
        value: ['31,00', '42,00'],
      },

      {
        title: 'Margherita',
        description: 'Muçarela, tomate, parmesão, manjericão e orégano',
        value: ['31,00', '42,00'],
      },

      {
        title: 'Milho',
        description: 'Muçarela, milho verde e orégano',
        value: ['30,00', '42,00'],
      },

      {
        title: 'Mista',
        description: 'Muçarela, presunto, cebola e orégano',
        value: ['31,00', '42,00'],
      },

      {
        title: 'Muçarela',
        description: 'Muçarela e oregano',
        value: ['30,00', '40,00'],
      },
    ]
  },

  {
    title: 'PIZZA DOCE',
    sizes: ['Brotinho', 'Pequena', 'Grande'],
    items: [
      {
        title: 'Banana',
        description: 'Muçarela, banana, canela e mel',
        value: ['15,00', '30,00', '42,00'],
      },

      {
        title: 'Doce de Leite',
        description: 'Muçarela, doce de leite e paçoquinha',
        value: ['15,00', '30,00', '42,00'],
      },

      {
        title: 'Romeu e Julieta',
        description: 'Muçarela, gorgonzola e goiabada',
        value: ['15,00', '30,00', '42,00'],
      },

      {
        title: 'Morango',
        description: 'Creme de avelã e morango',
        value: ['18,00', '34,00', '46,00'],
      },

      {
        title: 'Maçã',
        description: 'Muçarela, fatias de maçã verde e gorgonzola',
        value: ['15,00', '30,00', '42,00'],
      },

      {
        title: 'Damasco com Queijo Brie',
        description: 'Muçarela, queijo brie, damasco e mel',
        value: ['15,00', '30,00', '42,00'],
      },

      {
        title: 'Kiwi com Nutella',
        description: 'Nutella e kiwi',
        value: ['17,00', '31,00', '44,00'],
      },

      {
        title: 'Brigadeiro com Cereja',
        description: 'Muçarela, brigadeiro e cereja',
        value: ['17,00', '30,00', '41,00'],
      },
    ]
  },

  {
    title: 'PIZZA ESPECIAL',
    sizes: ['Pequena', 'Grande'],
    items:[
      {
        title: 'Atum',
        description: 'Muçarela, atum, azeitona, champignon, cebola e orégano',
        value: ['40,00', '62,00'],
      },

      {
        title: 'Brócolis',
        description: 'Muçarela,brócolis, palmito, catupiry, azeitona e orégano',
        value: ['42,00', '58,00'],
      },

      {
        title: 'Camarão',
        description: 'Muçarela, camarão, catupiry, parmesão e orégano',
        value: ['46,00', '72,00'],
      },

      {
        title: 'Carne Seca',
        description: 'Muçarela, carne seca, catupiry, cebola e orégano',
        value: ['42,00', '56,00'],
      },

      {
        title: 'Carne Seca com Banana da Terra',
        description: 'Muçarela, carne seca, banana da terra, catupiry, cebola e orégano',
        value: ['44,00', '68,00'],
      },

      {
        title: 'Frango com Catupiry',
        description: 'Muçarela, frango, catupiry, cebola e orégano',
        value: ['40,00', '56,00'],
      },

      {
        title: 'Frango com Milho',
        description: 'Muçarela, frango, catupiry, milho verde, cebola e orégano',
        value: ['40,00', '56,00'],
      },

      {
        title: 'Margherita',
        description: 'Muçarela, tomate uva, parmesão, majericão e orégano',
        value: ['38,00', '56,00'],
      },

      {
        title: 'Palmito',
        description: 'Muçarela, parmesão, palmito, tomate uva, manjericão e orégano',
        value: ['42,00', '56,00'],
      },

      {
        title: 'Peito de Peru',
        description: 'Muçarela, gorgonzola, peito de peru, champignon e orégano',
        value: ['40,00', '56,00'],
      },

      {
        title: 'Pepperoni',
        description: 'Muçarela, pepperoni, pimenta calabresa, cebola e orégano',
        value: ['42,00', '56,00'],
      },
    ]
  },

  {
    title: 'PIZZA ESPECIAL',
    sizes: ['Pequena', 'Grande'],
    items: [
      {
        title: 'Presunto Parma',
        description: 'Muçarela, presunto parma, tomate seco, rúcula, parmesão e orégano',
        value: ['46,00', '72,00'],
      },

      {
        title: 'Quatro Queijos',
        description: 'Muçarela, gorgonzola, parmesão, catupiry e orégano',
        value: ['42,00', '60,00'],
      },

      {
        title: 'Salaminho',
        description: 'Muçarela, salaminho, champignon, catupiry, parmesão, cebola e orégano',
        value: ['40,00', '56,00'],
      },

      {
        title: 'Vegana',
        description: 'Tomate, palmito, brócolis, champignon e tomate seco',
        value: ['42,00', '56,00'],
      },

      {
        title: 'Portuguesa',
        description: 'Muçarela, presunto, ovo, azeitona, cebola e orégano',
        value: ['42,00', '56,00'],
      },

      {
        title: 'Tomate Seco',
        description: 'Muçarela, parmesão, tomate seco, rúcula e orégano',
        value: ['38,00', '55,00'],
      },

      {
        title: 'Queijo Brie com Geleia de Pimenta',
        description: 'Muçarela, parmesão, tomate seco, rúcula e orégano',
        value: ['34,00', '72,00'],
      },

      {
        title: 'Calabresa com Catupiry',
        description: 'Muçarela, calabresa, cebola, orégano e catupiry',
        value: ['34,00', '57,00'],
      },

      {
        title: 'Atum com Catupiry',
        description: 'Muçarela, atum, azeitona, champignon, catupiry, cebola e orégano',
        value: ['42,00', '62,00'],
      },
    ]
  },
]